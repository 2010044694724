import { FC, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { isEmailValid } from 'helpers/validation';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import useDisableScroll from 'hooks/useDisableScroll';
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';

import { PrimaryButton } from 'components/buttons/primary-button';
import { EmailInput } from 'components/inputs/email-registration-modal';
import { LoadingAnimation } from 'components/loading-animation';
import LocalizedLink from 'components/localized-link';

import { Analytics } from 'services/analytics';

import { toggleModal } from 'data/actions/modals';
import { createAnonymousUser, createUserReveal, getUser } from 'data/actions/user';
import { getAbTestVariant } from 'data/selectors/abTests';
import { modalOptionsSelector } from 'data/selectors/modals';
import { queryUserIdSelector } from 'data/selectors/user';
import { convertDocumentDataSelector } from 'data/selectors/documents';

import { getPrevPage } from 'utils/prevPath';
import showToast from 'utils/toast';

import { PAGE_LINKS } from 'ts/constants/page-links';
import { GrowthBookTestType, TestVariants } from 'ts/enums/growthbook';

import ModalLayout from '../baseModal/base';
import s from './enter-email-popup.module.scss';
import EmailModalB from './test_email_modal_2_4_5/email_modal_B';
import EmailModalC from './test_email_modal_2_4_5/email_modal_C';

const EnterEmailAddressModal: FC = () => {
  const dispatch = useDispatch();
  const navigate = useLocaleNavigate();
  const { t, i18n } = useTranslation();
  const newEnterEmailModalTestVariant = useSelector(
    getAbTestVariant(GrowthBookTestType.ABC_NEW_EMAIL_MODAL_2_4_5)
  );

  const isLoading = false;
  useDisableScroll();
  useCloseModalOnBack();

  const {
    handleConvertDocument,
    keepOpenedOnSuccess,
    handleTranslateDocument,
    handleCustomNavigate,
  } = useSelector(modalOptionsSelector);

  const dataToConvert = useSelector(convertDocumentDataSelector());

  const queryUserId = useSelector(queryUserIdSelector);

  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });

  const screenConfigName = useMemo(() => {
    if (newEnterEmailModalTestVariant === TestVariants.A) {
      return 'simple_email_modal';
    }
    if (newEnterEmailModalTestVariant === TestVariants.B) {
      return 'email_social_proofs';
    }
    if (newEnterEmailModalTestVariant === TestVariants.C) {
      return 'email_social_proofs_google';
    }

    return 'google_without_password';
  }, [newEnterEmailModalTestVariant]);

  useEffect(() => {
    Analytics.sendEvent({
      event: 'email_enter_modal_view',
      data: {
        screen_config_name: screenConfigName,
        fromPage: getPrevPage() || '',
      },
    });
  }, [screenConfigName]);

  const validateEmail = (email: string) => {
    if (!email?.length) {
      setErrors((prev) => ({
        ...prev,
        email: t('input_email.email_empty'),
      }));
      return false;
    } else if (email?.length > 50) {
      setErrors((prev) => ({
        ...prev,
        email: t('input_email.max_length_error'),
      }));
      return false;
    } else if (!isEmailValid(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('input_email.validation_error'),
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };

  const onFailedSignUp = (errorMessage?: string) => {
    Analytics.sendEvent({
      event: 'registration_status',
      data: {
        placement: 'email_modal',
        funnel: getPrevPage() || '',
        email: email,
        status: 'success',
        fail_reason: errorMessage || '',
        type: 'email',
      },
    });
    showToast(
      'error',
      <>
        {t('popups.enter_email_popup.email_exists_login')}{' '}
        <LocalizedLink href={PAGE_LINKS.LOGIN} className="underline">
          {t('popups.enter_email_popup.login')}
        </LocalizedLink>
      </>,
      10000,
      'signUp'
    );
  };

  const onSuccessSignUp = () => {
    const handleNavigate = (route: string) => {
      dispatch(toggleModal({ visible: false }));
      navigate(route);
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };
    window.onbeforeunload = null;

    dispatch(getUser({ onFailed: () => dispatch(createAnonymousUser(queryUserId || '')) }));

    void Analytics.sendEvent({
      event: 'email_enter_tap',
      data: { email, type: 'email' },
    });

    Analytics.sendEvent({
      event: 'registration_status',
      data: {
        placement: 'email_modal',
        funnel: getPrevPage() || '',
        email: email,
        status: 'success',
        type: 'email',
      },
    });

    if (typeof handleConvertDocument === 'function') {
      handleConvertDocument();
      if (!keepOpenedOnSuccess) {
        dispatch(toggleModal({ visible: false }));
      }
      return;
    }
    if (typeof handleTranslateDocument === 'function') {
      handleTranslateDocument();
      if (!keepOpenedOnSuccess) {
        dispatch(toggleModal({ visible: false }));
      }
      return;
    }

    if (typeof handleCustomNavigate === 'function') {
      handleCustomNavigate();
      if (!keepOpenedOnSuccess) {
        dispatch(toggleModal({ visible: false }));
      }
      return;
    }
    handleNavigate(PAGE_LINKS.DASHBOARD);
    if (!keepOpenedOnSuccess) {
      dispatch(toggleModal({ visible: false }));
    }
  };

  const onClosePopup = () => {
    Analytics.sendEvent({
      event: 'enter_email_close_tap',
    });
  };

  const handleDownLoadFile = () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) return;

    dispatch(
      createUserReveal({
        email,
        last_uploaded_filename: dataToConvert?.filename,
        onSuccess: onSuccessSignUp,
        onFailed: onFailedSignUp,
      })
    );
  };

  if (newEnterEmailModalTestVariant === TestVariants.C) {
    return (
      <EmailModalC
        onClosePopup={onClosePopup}
        setEmail={setEmail}
        email={email}
        errors={errors}
        isLoading={isLoading}
        handleDownLoadFile={handleDownLoadFile}
      />
    );
  }

  if (newEnterEmailModalTestVariant === TestVariants.B) {
    return (
      <EmailModalB
        onClosePopup={onClosePopup}
        setEmail={setEmail}
        email={email}
        errors={errors}
        isLoading={isLoading}
        handleDownLoadFile={handleDownLoadFile}
      />
    );
  }

  return (
    <ModalLayout canClose closePopup={onClosePopup} dataTestId="enter-email-modal">
      <div className={classNames(s.enterEmailPopup, 'font-primaryB')}>
        <h2 className="!text-[20px] !text-[#1D1D1D] !font-bold">
          {t('popups.enter_email_popup.enter_email')}
          {i18n.language === 'ja' && (
            <div className="mb-4 text-[10px] text-center">
              {t('popups.enter_email_popup.microcopy')}
            </div>
          )}
        </h2>

        <EmailInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          onBlur={() => {}}
          value={email}
          error={errors?.email}
          onDomainClick={(email: string) => setEmail(email)}
        />

        <PrimaryButton
          onClick={handleDownLoadFile}
          className={classNames(
            s.submitButton,
            '!bg-[#5F30E2] rounded-xl text-[16px] leading-[22px] py-5 mobile:text-[20px] font-bold mobile:py-3'
          )}
          disabled={isLoading}
          dataTestId="enter-email-modal-submit"
        >
          {isLoading ? (
            <LoadingAnimation currentState="loading" className="!h-[22px]" />
          ) : (
            t('popups.enter_email_popup.download')
          )}
        </PrimaryButton>
        <p
          className="mt-4 mobile:mt-3 text-[10px] leading-[15px] mobile:text-[12px] mobile:leading-[16px]
          text-[#1D1D1D] w-full max-w-[500px] text-center"
        >
          {t('popups.enter_email_popup.by_clicking')}{' '}
          <a href={PAGE_LINKS.TERMS} rel="noreferrer" target="_blank" className="text-[#5F30E2]">
            {t('popups.enter_email_popup.terms_and_conditions')}
          </a>{' '}
          {t('popups.enter_email_popup.and')}{' '}
          <a
            href={PAGE_LINKS.PRIVACY_POLICY}
            rel="noreferrer"
            target="_blank"
            className="text-[#5F30E2]"
          >
            {t('popups.enter_email_popup.privacy_policy')}
          </a>
        </p>
      </div>
    </ModalLayout>
  );
};

export default EnterEmailAddressModal;
