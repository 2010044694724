import { getCurrencySymbolByCountryCode } from 'helpers/getCurrency';
import { createSelector } from 'reselect';

import { UserStatus } from 'ts/enums/user.status';

const baseUserSelector = (state: any) => state?.user;

export const isUserAuthenticated = createSelector(
  baseUserSelector,
  (user) =>
    user?.userData?.status === UserStatus.REGISTERED ||
    user?.userData?.status === UserStatus.TEMPORARY
);

export const userDataSelector = createSelector(baseUserSelector, (user) => user?.userData || {});

export const userSubscriptionSelector = createSelector(
  baseUserSelector,
  (user) => user?.userData?.subscription
);

export const userIdSelector = createSelector(baseUserSelector, (user) => user?.userData?.id);

export const userEmailSelector = createSelector(baseUserSelector, (user) => user?.userData?.email);

export const userFirstName = createSelector(baseUserSelector, (user) => user?.userData?.firstName);

export const userLastName = createSelector(baseUserSelector, (user) => user?.userData?.lastName);

export const isGoogleUser = createSelector(baseUserSelector, (user) => user?.userData?.googleAuth);

export const isShowUAEAddressSelector = createSelector(baseUserSelector, (user) =>
  ['AE', 'ARE', 'SG', 'SGP'].includes(user?.countryCode)
);

export const isJapanUserSelector = createSelector(baseUserSelector, (user) =>
  ['JP', 'JPN'].includes(user?.countryCode)
);

export const isUSAUserSelector = createSelector(baseUserSelector, (user) =>
  ['US', 'USA'].includes(user?.countryCode)
);

export const userCountryCodeSelector = createSelector(
  baseUserSelector,
  (user) => user?.countryCode
);

export const pageViewSelector = createSelector(baseUserSelector, (user) => user?.pageView);

export const trustPilotLinkSelector = createSelector(
  baseUserSelector,
  (user) => user?.trustPilotLink
);

export const userCurrencySelector = createSelector(baseUserSelector, (user) =>
  getCurrencySymbolByCountryCode(user?.countryCode)
);

export const idSelector = createSelector(
  baseUserSelector,
  (user) => user?.userData?.id || user?.userId
);

export const queryUserIdSelector = createSelector(baseUserSelector, (user) => user?.queryUserId);
