import { FormEvent, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import suggestedQuestionsIcon from 'assets/ai-summarizer/suggested-questions.svg';
import { HttpStatusCode } from 'axios';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import apiEndpoints from 'use-cases/ai-summarizer/api/api';
import { redirectToChoosePlan } from 'use-cases/ai-summarizer/redirect-to-choose-plan';
import { validateLimit } from 'use-cases/ai-summarizer/validate-limit';
import { waitForThreadCompletion } from 'use-cases/ai-summarizer/wait-for-thread-completion';

import { Analytics } from 'services/analytics';

import {
  setCurrentThreadAssistantMessage,
  setCurrentThreadUserMessage,
  setFreeLimitReached,
} from 'data/actions/aiSummarizer';
import { triggerLoadingActionWithId } from 'data/actions/loading';
import { toggleModal } from 'data/actions/modals';
import { currentThreadDataSelector, currentThreadIdSelector } from 'data/selectors/aiSummarizer';
import { getLoadingSelector } from 'data/selectors/loading';
import { userDataSelector } from 'data/selectors/user';

import { Logger } from 'utils/logger';

import { EModalTypes } from 'ts/enums/modal.types';

interface AISuggestedQuestionsProps {
  suggestedQuestions: string[] | undefined;
}

export const AISuggestedQuestions = ({ suggestedQuestions }: AISuggestedQuestionsProps) => {
  const dispatch = useDispatch();
  const user = useSelector(userDataSelector);
  const currentThreadData = useSelector(currentThreadDataSelector);
  const currentThreadId = useSelector(currentThreadIdSelector);
  const loadingNewMessageInThread = useSelector(getLoadingSelector('newMessageInThread'));

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);

  const { t } = useTranslation();

  const logger = useMemo(() => new Logger('User Input'), []);

  const currentLoading = loadingNewMessageInThread[currentThreadId as string];

  const handleSubmit = async (e: FormEvent, message: string) => {
    e.preventDefault();

    if (currentLoading) {
      return;
    }

    Analytics.sendEvent({
      event: 'pdf_summarizer_suggested_question_tap',
    });

    const isReachedLimit = await validateLimit(user, currentThreadData);

    if (currentThreadId) {
      try {
        if (!isReachedLimit) {
          dispatch(setCurrentThreadUserMessage(message));
          dispatch(triggerLoadingActionWithId(['newMessageInThread'], true, currentThreadId));
        }

        const response = await apiEndpoints.sendMessage(currentThreadId, message);

        const runId = response.runId;

        const { content, chatId } = await waitForThreadCompletion(currentThreadId, runId as any);

        const summary = content;

        if (summary && chatId === currentThreadId) {
          dispatch(
            setCurrentThreadAssistantMessage({
              id: runId,
              threadId: chatId,
              role: 'assistant',
              message: summary,
            })
          );

          dispatch(triggerLoadingActionWithId(['newMessageInThread'], false, currentThreadId));
        }
      } catch (error) {
        if ((error as any)?.response.data.statusCode === HttpStatusCode.PaymentRequired) {
          dispatch(setFreeLimitReached(true));

          if (!user?.email) {
            dispatch(toggleModal({ visible: true, type: EModalTypes.ENTER_EMAIL_AI_SUMMARIZER }));

            return;
          }

          redirectToChoosePlan(navigate);

          return;
        }

        logger.log({ error } as any);
      }
    }
  };

  return (
    <div className="px-3 py-2 rounded-[16px] mt-4 bg-[#F6F6F6]">
      <motion.div
        initial={false}
        className={`cursor-pointer flex items-center mr-[20px] justify-between gap-2 py-2 ${
          isOpen ? 'mb-2' : ''
        }`}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="ai-summarizer-suggested-questions-toggle"
      >
        <div className="flex items-center gap-2">
          <img src={suggestedQuestionsIcon} alt="Suggested questions" />
          <span className="text-sm font-bold">{t('ai_summarizer.suggested_questions')}</span>
        </div>

        <motion.svg
          animate={{ rotate: isOpen ? 0 : 180 }}
          transition={{ duration: 0.2 }}
          className="mt-[-2px] ml-2 w-5 h-5"
          fill="none"
          stroke="#575757"
          strokeWidth={4}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M19 9l-7 7-7-7" />
        </motion.svg>
      </motion.div>

      <AnimatePresence initial={false}>
        {isOpen && suggestedQuestions && suggestedQuestions.length > 0 && (
          <motion.ul
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.1 }}
            className="flex flex-col gap-2"
          >
            {suggestedQuestions.map((question) => (
              <motion.li
                key={question}
                className={classNames(
                  'text-sm text-[#1D1D1D] hover:text-[#1D1D1D] cursor-pointer bg-[#fff] px-4 py-2 rounded-[8px] flex items-center justify-between border border-transparent hover:border-[#192CD2] group transition duration-100',
                  {
                    'pointer-events-none opacity-50': currentLoading,
                  }
                )}
                onClick={(e) => handleSubmit(e, question)}
                data-testid={`ai-summarizer-suggested-question-${question.slice(0, 10).replace(/\s+/g, '-').toLowerCase()}`}
              >
                {question}

                <div className="min-h-[24px] min-w-[24px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14 8.00033L10.6667 4.66699M14 8.00033L10.6667 11.3337M14 8.00033H6"
                      stroke="#575757"
                      className="group-hover:stroke-[#192CD2]"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};
