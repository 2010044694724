import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import useFileUploadAndConvertNewEditorFlow241 from 'hooks/fileFlows/ab_new_editor_flow_2_4_1/useFileUploadAndConvert';
import useFileUploadAndConvertMultiple from 'hooks/fileFlows/useFileUploadAndConvertMultiple';
import useFileUploadAndEdit from 'hooks/fileFlows/useFileUploadAndEdit';
import useHandleSelectFile from 'hooks/fileFlows/useHandleSelectFile';

import { PrimaryButton } from 'components/buttons/primary-button';
import { DragNDropABImportExportEditor244 } from 'components/drag-n-drop/view/route_ab-import_export_editor_2_4_4';
import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import {
  pushEditMultipleDocumentsDataABImportExportEditor244,
  setEditMultipleDocumentsDataABImportExportEditor244,
} from 'data/actions/documents';
import { selectEditMultipleDocuments } from 'data/selectors/documents';
import { modalOptionsSelector } from 'data/selectors/modals';

import { convertToPdf } from 'utils/convertToPdf';
import {
  areOnlyPdfAndImages,
  isPdfOrImage,
} from 'utils/isEditorFlow_ab-import_export_editor_2_4_4';
import { mergePdfFilesABImportExportEditor244 } from 'utils/mergePdf';
import showToast from 'utils/toast';

import { fromMimetype } from 'ts/types/file';

import { UploadFileList } from './components/UploadFilestList';

export const MAX_FILES_ALLOWED = 10;

const UploadModalABImportExportEditor244 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { service } = useSelector(modalOptionsSelector);
  const uploadedFiles = useSelector(selectEditMultipleDocuments);
  const [error, setError] = useState<string | null>(null);
  const [unsupportedFiles, setUnsupportedFiles] = useState<File[]>([]);

  const { handleUploadFile: uploadFileEditorFlow } = useFileUploadAndConvertNewEditorFlow241({
    service,
  });

  const { handleUploadFile: handleUploadFilesMultiple } = useFileUploadAndConvertMultiple({
    service,
  });
  const { handleUploadFile: handleUploadFilesWithEditor } = useFileUploadAndEdit({
    service,
  });

  const { handleSelectFile: handleUploadFilesAndConvertSingle } = useHandleSelectFile({
    service,
  });

  const isEditorFlow = service.serviceType === 'EDITOR';

  const handleUploadFile = useCallback(
    (files: File[]) => {
      dispatch(pushEditMultipleDocumentsDataABImportExportEditor244(uploadedFiles, files));
      files.forEach((file) => {
        Analytics.sendEvent({
          event: 'file_upload_status',
          data: {
            features_name: service?.path?.replace('/', '') || '',
            type: 'click', // change it to 'drag_and_drop', 'pasted_from_buffer' based on the upload method
            file_name: file.name,
            file_type: file.type,
            file_format: `.${file?.name?.split('.')?.pop()}`,
          },
        });
      });
      Analytics.sendEvent({
        event: 'file_from_provider_chosen',
        data: { features_name: service?.path?.replace('/', '') || '', method: 'click' },
      });
    },
    [dispatch, uploadedFiles, service]
  );

  const handleMergeFiles = useCallback(
    async (files: File[]) => {
      const mergedFile = await mergePdfFilesABImportExportEditor244(files, 'merged.pdf');
      isEditorFlow ? handleUploadFilesWithEditor(mergedFile) : uploadFileEditorFlow(mergedFile);
    },
    [uploadFileEditorFlow, handleUploadFilesWithEditor, isEditorFlow]
  );

  const handleConvertFiles = useCallback(async () => {
    const convertedFiles = await Promise.all(
      uploadedFiles.map(async (file: File) => convertToPdf(file) || file)
    );
    handleMergeFiles(convertedFiles);
  }, [uploadedFiles, handleMergeFiles]);

  const saveDataToStorage = useCallback(() => {
    dispatch(setEditMultipleDocumentsDataABImportExportEditor244(uploadedFiles));
  }, [dispatch, uploadedFiles]);

  const handleConvertSeparately = useCallback(() => {
    saveDataToStorage();
    Analytics.sendEvent({
      event: 'upload_modal_confirm_tap',
      data: {
        action_type: 'convert_files_separately',
        files_count: uploadedFiles.length,
        open_in_editor: false,
        local_page: 'upload_modal',
        uploaded_formats: Array.from(
          new Set(uploadedFiles.map((file: File) => fromMimetype(file.type)))
        ),
      },
    });
    handleUploadFilesMultiple(uploadedFiles, 'archive');
  }, [saveDataToStorage, handleUploadFilesMultiple, uploadedFiles]);

  useEffect(() => {
    error && showToast('error', error, 5000, 'import_modal_ab_import_export_editor_2_4_4');
  }, [error]);

  const handleMergeAndConvertFiles = useCallback(() => {
    saveDataToStorage();
    const unsupportedFiles = uploadedFiles.filter((file: File) => !isPdfOrImage(file));
    const openInEditor = areOnlyPdfAndImages(uploadedFiles);
    Analytics.sendEvent({
      event:
        unsupportedFiles.length > 0 ? 'restricted_modal_confirm_tap' : 'upload_modal_confirm_tap',
      data: {
        action_type: 'merge_files',
        files_count: uploadedFiles.length,
        open_in_editor: openInEditor,
        local_page: 'upload_modal',
        uploaded_formats: Array.from(
          new Set(uploadedFiles.map((file: File) => fromMimetype(file.type)))
        ),
      },
    });

    if (areOnlyPdfAndImages(uploadedFiles)) {
      handleConvertFiles();
    } else if (isEditorFlow) {
      if (unsupportedFiles.length > 0) {
        setUnsupportedFiles(unsupportedFiles);
        showToast(
          'error',
          t('popups.upload_modal_ab_import_export_editor_2_4_4.toast_error_message'),
          5000,
          'import_modal_ab_import_export_editor_2_4_4'
        );
      } else {
        handleUploadFilesMultiple(uploadedFiles, 'merge');
      }
    } else {
      handleUploadFilesMultiple(uploadedFiles, 'merge');
    }
  }, [
    saveDataToStorage,
    handleConvertFiles,
    handleUploadFilesMultiple,
    uploadedFiles,
    isEditorFlow,
    t,
  ]);

  const handleBasicFlow = () => {
    const unsupportedFiles = uploadedFiles.filter((file: File) => !isPdfOrImage(file));
    const openInEditor = unsupportedFiles.length === 0 && isEditorFlow;
    Analytics.sendEvent({
      event:
        unsupportedFiles.length > 0 ? 'restricted_modal_confirm_tap' : 'upload_modal_confirm_tap',
      data: {
        action_type: 'continue_with_single_file',
        files_count: uploadedFiles.length,
        open_in_editor: openInEditor,
        local_page: 'upload_modal',
        uploaded_formats: fromMimetype(uploadedFiles[0].type),
      },
    });
    saveDataToStorage();
    if (isEditorFlow) {
      if (unsupportedFiles.length > 0) {
        setUnsupportedFiles(unsupportedFiles);
        showToast(
          'error',
          t('popups.upload_modal_ab_import_export_editor_2_4_4.toast_error_message'),
          5000,
          'import_modal_ab_import_export_editor_2_4_4'
        );
      } else {
        handleUploadFilesWithEditor(uploadedFiles[0]);
      }
    } else {
      handleUploadFilesAndConvertSingle(uploadedFiles[0]);
    }
  };

  useEffect(() => {
    const renderScreenConfigName = () => {
      if (isEditorFlow) {
        return 'edit_funnels';
      } else {
        return 'convert_funnels';
      }
    };

    Analytics.sendEvent({
      event: 'upload_file_modal_view',
      data: {
        screen_config_name: renderScreenConfigName(),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderButton = () => {
    if (uploadedFiles.length === 1) {
      return (
        <PrimaryButton
          onClick={handleBasicFlow}
          icon="arrow-right"
          iconPosition="right"
          className="min-w-full mt-6 tablet:min-w-[230px] bg-[#D2294B] h-[57px] hover:bg-[#f4476ae0] text-[18px] py-5 px-24 mobile:py-[17px]"
        >
          {t('popups.upload_modal_ab_import_export_editor_2_4_4.continue_button_label')}
        </PrimaryButton>
      );
    }
    if (isEditorFlow) {
      return (
        <PrimaryButton
          onClick={handleMergeAndConvertFiles}
          icon="arrow-right"
          iconPosition="right"
          className="min-w-full mt-6 tablet:min-w-[200px] bg-[#D2294B] h-[57px] hover:bg-[#f4476ae0] text-[18px] py-5 px-24 mobile:py-[17px]"
        >
          {t('popups.upload_modal_ab_import_export_editor_2_4_4.merge_and_edit_button')}
        </PrimaryButton>
      );
    }
    return (
      <div className="flex mt-6 w-full justify-end">
        <PrimaryButton
          onClick={handleConvertSeparately}
          icon="convert"
          iconPosition="left"
          className={classNames(
            'bg-white border-[1px] border-[#1D1D1D] text-[#1D1D1D] max-w-[480px] h-[57px] mr-6 text-[18px] flex items-center space-x-2 py-5 px-4 hover:bg-white hover:border-[#1D1D1D] hover:text-[#1D1D1D]'
          )}
        >
          {t('popups.upload_modal_ab_import_export_editor_2_4_4.convert_sepetely')}
        </PrimaryButton>

        <PrimaryButton
          onClick={handleMergeAndConvertFiles}
          icon="arrow-right"
          iconPosition="right"
          className="min-w-full tablet:min-w-[200px] bg-[#D2294B] h-[57px] hover:bg-[#f4476ae0] text-[18px] py-5 px-6 mobile:py-[17px] flex items-center space-x-2"
        >
          {t('popups.upload_modal_ab_import_export_editor_2_4_4.merge_and_convert_button')}
        </PrimaryButton>
      </div>
    );
  };

  const closeModal = () => {
    dispatch(setEditMultipleDocumentsDataABImportExportEditor244([]));
    Analytics.sendEvent({
      event: 'upload_file_modal_close_tap',
    });
  };

  useEffect(() => {
    // Disable scrolling on the body when the modal is open
    document.body.style.overflow = 'hidden';
    // Cleanup function to reset the overflow style when the modal is closed
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <ModalLayout canClose closePopup={closeModal} dataTestId="choose-format-modal">
      <div className="flex flex-col text-[#1D1D1D] items-center w-full mobile:min-w-[630px] mobile:max-w-[600px] p-8">
        <h2 className="text-[25px] font-extrabold leading-[32.5px] mb-6">
          {t('popups.upload_modal_ab_import_export_editor_2_4_4.title')}
        </h2>
        <DragNDropABImportExportEditor244
          format={service.from}
          onSelectFile={handleUploadFile}
          color={service.mainColor}
          onClick={service.onUploadClick}
          setError={setError}
          service={service}
        />
        {uploadedFiles.length > 0 && (
          <>
            <div className="flex flex-row items-center justify-between w-full px-2 pb-3 mt-6">
              <h2 className="text-[18px] font-extrabold leading-[32.5px]">
                {t('popups.upload_modal_ab_import_export_editor_2_4_4.uploaded_files')}
              </h2>
              <h2 className="text-[18px] font-bold">
                {uploadedFiles.length}/{MAX_FILES_ALLOWED}
              </h2>
            </div>
            <div className="overflow-y-auto max-h-60 w-full">
              <UploadFileList unsupportedFiles={unsupportedFiles} />
            </div>
            {renderButton()}
          </>
        )}
      </div>
    </ModalLayout>
  );
};

export default UploadModalABImportExportEditor244;
