import { checkRecaptcha } from 'helpers/checkRecaptcha';
import { countPdfPages } from 'helpers/countPdfPages';
import { AnyAction, Dispatch } from 'redux';

import { Analytics } from 'services/analytics';

import { formatFileSize } from 'utils/formatFileSize';
import { transliterate } from 'utils/transliterate';

import { EReduxTypes } from 'ts/enums/redux.types';
import {
  ICompressDocumentDto,
  IConvertDocumentDto,
  IEditDocumentDto,
  IMergeDocument,
  IMergeDocumentDto,
  IOCRDocumentDto,
  ISplitDocumentDto,
  ITranslateDocumentDto,
} from 'ts/interfaces/documents/document';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IFormService, IService } from 'ts/interfaces/services/service';
import { ApiFile } from 'ts/types/file';

declare global {
  interface Window {
    google?: {
      accounts: {
        id: {
          initialize: (config: any) => void;
          prompt: (callback: (notification: any) => void) => void;
          cancel: () => void;
        };
      };
    };
    grecaptcha: any;
    hj: any;
    fbq: any;
    gtag: any;
    dataLayer: any;
    google_optimize: any;
    experimentsCallback: any;
    pdfUi: any;
    uploadedFile?:
      | {
          url: string;
          name: string;
        }
      | undefined;
    zE: any;
  }
}

export const saveUploadedFile =
  ({
    fileData,
    onSuccess,
    onFailed,
  }: {
    fileData: {
      size: number;
      filename: string;
      key: string;
      pagesCount: number;
      fileId?: string;
      uploadLink?: string;
    };
    onSuccess?: unknown;
    onFailed?: unknown;
    service?: IService | IFormService;
  }) =>
  (dispatch: Dispatch<any>): void => {
    const action: IReduxAction = {
      type: EReduxTypes.SAVE_UPLOADED_FILE,
      payload: {
        method: 'POST',
        endpoint: 'api/v1/files/file-uploaded',
        body: fileData,
      },
      onSuccess: (res) => {
        const result = {
          id: res?.id,
          awsUrl: res?.aws_url,
          url: res?.url,
          filename: res?.filename,
          size: res?.size,
          internalType: res?.internal_type,
          processingStatus: res?.processing_status,
          createdAt: res?.created_at,
        };

        localStorage.setItem('fileToPrint', JSON.stringify(result));
        localStorage.setItem('fileIdToSend', result.id);
        localStorage.setItem('fileToSend', JSON.stringify(result));
        if (typeof onSuccess === 'function') onSuccess(result);
      },
      onFailed: (res: any) => {
        if (typeof onFailed === 'function') onFailed(res);
      },
    };

    dispatch(action);
  };

export const getUploadLink =
  ({
    filename,
    onSuccess,
    onFailed,
    service,
  }: {
    filename: string | string[];
    onSuccess?: unknown;
    onFailed?: unknown;
    service?: IService | IFormService;
  }) =>
  (dispatch: Dispatch<any>, getState: any): void => {
    const state = getState();
    const action: IReduxAction = {
      type: EReduxTypes.GET_UPLOAD_LINK,
      payload: {
        method: 'POST',
        endpoint: 'api/v1/files/upload-link',
        body: {
          filenames: typeof filename === 'string' ? [filename] : filename,
        },
      },
      onSuccess: (res) => {
        // TO-DO: remove this after devops update
        const result = res?.map((item: any) => ({
          ...item,
          url: item.url,
        }));

        if (typeof onSuccess === 'function') onSuccess(result);
      },
      onFailed: () => {
        if (typeof onFailed === 'function') onFailed();
      },
    };

    if (
      window?.grecaptcha?.enterprise &&
      state?.user?.userData?.subscription?.status !== 'active' &&
      state?.user?.userData?.count_uploaded_files &&
      state?.user?.userData?.count_uploaded_files >= (process.env.REACT_APP_CAPTCHA_LIMIT || 10)
    ) {
      checkRecaptcha()
        .then((res: any) => {
          if (action.payload) {
            action.payload.params = {
              captcha_token: res,
              captcha_action: 'USER_TENTH_FILE',
            };

            dispatch(action);
          }
        })
        .catch(() => dispatch(action));
    } else dispatch(action);
  };

export const downloadDocument = (
  documentId: string,
  onFailed?: unknown,
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DOWNLOAD_DOCUMENT,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/download/${documentId}`,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  localStorage.removeItem('downloadFromDashboard');
  return action;
};

export const downloadDocumentAndUpdatedDocumentId = (
  documentId: string,
  onFailed?: unknown,
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DOWNLOAD_DOCUMENT_AND_UPDATE_DOCUMENT_ID,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/download/${documentId}`,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const updateDownloadProgress = (progress: number): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_DOWNLOAD_PROGRESS,
    data: {
      progress,
    },
  };
  return action;
};

export const updateEditFilename = (filename: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_EDIT_FILENAME,
    data: {
      filename,
    },
  };
  return action;
};

export const setConvertDocumentData = ({
  filename,
  key,
  size,
  pagesCount = 1,
  from,
  to,
  url,
  serviceType,
  fileList,
}: IConvertDocumentDto): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_CONVERT_DOCUMENT_DATA,
    data: {
      filename,
      size,
      key,
      pagesCount,
      from,
      to: to || 'PNG',
      url,
      serviceType,
      fileList,
    },
  };
  return action;
};

export const setTranslateDocumentData = ({
  filename,
  key,
  size,
  pagesCount = 1,
  fromLang,
  toLang,
  url,
  serviceType,
  fileList,
}: ITranslateDocumentDto): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_TRANSLATE_DOCUMENT_DATA,
    data: {
      filename,
      size,
      key,
      pagesCount,
      fromLang,
      toLang,
      url,
      serviceType,
      fileList,
    },
  };
  return action;
};

export const updateEditDocumentData = (data: { from?: string; to?: string }): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_EDIT_DOCUMENT_DATA,
    data,
  };
  return action;
};

export const setEditDocumentData = ({
  filename,
  key,
  size,
  pagesCount = 1,
}: IConvertDocumentDto | IEditDocumentDto): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_EDIT_DOCUMENT_DATA,
    data: {
      filename,
      size,
      key,
      pagesCount,
    },
  };
  return action;
};
export const setEditMultipleDocumentsDataABImportExportEditor244 = (
  documents: Array<any>
): AnyAction => {
  return {
    type: EReduxTypes.SET_EDIT_MULTIPLE_DOCUMENT_DATA,
    data: documents,
  };
};

export const pushEditMultipleDocumentsDataABImportExportEditor244 = (
  existingDocuments: any[],
  newFiles: any[],
  options: {
    onSuccess?: unknown;
    ignoreEvent?: boolean;
  } = {}
): AnyAction => {
  const updatedDocuments = [
    ...(Array.isArray(existingDocuments) ? existingDocuments : []),
    ...newFiles,
  ];

  return {
    type: EReduxTypes.SET_EDIT_MULTIPLE_DOCUMENT_DATA,
    data: updatedDocuments,
    onSuccess: (res: any) => {
      if (typeof options.onSuccess === 'function') options.onSuccess(res);
    },
  };
};

export const getConvertedFile = ({
  fileId,
  onSuccess,
  onFailed,
  setFileToRedux = true,
  format,
}: {
  fileId: string;
  onSuccess?: unknown;
  onFailed?: unknown;
  setFileToRedux?: boolean;
  format?: string;
}): AnyAction => {
  const body: any = {};

  if (format) {
    body.format = format;
    body.timestamp = new Date().getTime() - 1000 * 60 * 5;
  }
  const action: IReduxAction = {
    type: EReduxTypes.GET_CONVERTED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/converted`,
      body,
    },
    data: {
      setFileToRedux,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const getOptimizedFile = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_OPTIMIZED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/optimized`,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const getTranslatedFile = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_TRANSLATED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/translated`,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const convertDocument = (
  { filename, key, size, pagesCount = 1, from, to, url }: IConvertDocumentDto,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint:
        from === 'PDF' && to === 'PDF'
          ? 'api/v1/editor/file-uploaded'
          : 'api/v1/converter/file-uploaded',
      body: {
        filename,
        size,
        key,
        pagesCount,
        ...(!(from === 'PDF' && to === 'PDF') && { from, to: to || 'PNG' }),
      },
    },
    data: {
      filename,
      size,
      key,
      pagesCount,
      ...(!(from === 'PDF' && to === 'PDF') && { from, to: to || 'PNG', url }),
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: from,
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const translateDocument = (
  { filename, key, size, pagesCount = 1, toLang, url }: ITranslateDocumentDto,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.TRANSLATE_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: `api/v1/translate/file-uploaded`,
      body: {
        filename,
        size,
        key,
        pagesCount,
        targetLanguageCode: localStorage.getItem('outputLang'),
      },
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const translateFirstPage = (
  file: File | ArrayBuffer,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.TRANSLATE_FIRST_PAGE,
    payload: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      endpoint: `api/v1/translate/page?targetLanguage=${localStorage.getItem('outputLang')}`,
      body: file,
      responseType: 'arraybuffer',
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const getDocuments = (onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_DOCUMENTS,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files`,
    },
    delayLoading: 500,
    components: ['get_documents'],
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const setSelectedFile = (file: ApiFile | null): IReduxAction => {
  return {
    type: EReduxTypes.SET_SELECTED_FILE,
    data: { file },
  };
};

export const updateFileName = (
  documentId: string,
  newFileName: string,
  onFailed?: unknown,
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_FILENAME,
    payload: {
      method: 'PATCH',
      endpoint: `api/v1/files/${documentId}/rename`,
      body: {
        filename: newFileName,
      },
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const shareFileLink = (
  documentId: string,
  onSuccess?: (response: { url: string }) => void
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SHARE_FILE_LINK,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/${encodeURIComponent(documentId)}/share-link`,
    },
    onSuccess: (res: { url: string }) => {
      if (typeof onSuccess === 'function') {
        onSuccess(res);
      }
    },
  };
  return action;
};

export const sendFileViaEmail = (payload: any, onSuccess?: (response: any) => void): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SEND_FILE_VIA_EMAIL,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/files/share-via-email',
      body: payload,
    },
    onSuccess: (response: any) => {
      if (typeof onSuccess === 'function') {
        onSuccess(response);
      }
    },
  };
  return action;
};

export const getDocumentById = (
  documentId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_DOCUMENT_BY_ID,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/download/${documentId}`,
    },
    data: {
      documentId,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const deleteDocumentById = (documentId: string, onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DELETE_DOCUMENT_BY_ID,
    payload: {
      method: 'DELETE',
      endpoint: `api/v1/files/${documentId}`,
    },
    data: {
      documentId,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const convertedFile = (
  documentId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${documentId}/converted`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
  };
  return action;
};

export const setPDFfileContent = ({
  document,
  onSuccess,
  onFailed,
}: {
  document: ArrayBuffer | string | null;
  onSuccess?: unknown;
  onFailed?: unknown;
}) => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_PDF_FILE_CONTENT,
    data: {
      document,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const setDocumentForTranslate = ({
  document,
  onSuccess,
  onFailed,
}: {
  document: File | ArrayBuffer | null;
  onSuccess?: (res: any) => void;
  onFailed?: (error: any) => void;
}) => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_DOCUMENT_FOR_TRANSLATE,
    data: {
      document,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (error: any) => {
      if (typeof onFailed === 'function') onFailed(error);
    },
  };
  return action;
};

export const downloadAndConvertToBuffer = (
  documentId: string,
  onFailed?: unknown,
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DOWNLOAD_AND_CONVERT_TO_BUFFER,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/download/${documentId}`,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') {
        fetch(res.url)
          .then((file) => {
            return file.arrayBuffer();
          })
          .then((buffer) => {
            onSuccess(buffer);
          });
      }
    },
  };
  return action;
};

export const uploadEditDocument = (
  { filename, key, size, pagesCount = 1 }: IEditDocumentDto,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPLOAD_EDIT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/editor/file-uploaded',
      body: {
        filename,
        size,
        key,
        pagesCount,
      },
    },
    data: {
      filename,
      size,
      key,
      pagesCount,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: '.pdf',
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const convertExistingDocument = ({
  fileId,
  to,
  from,
  onSuccess,
  onFailed,
}: {
  fileId: string;
  to: string;
  from: string;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_EXISTING_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/converter/existing',
      body: {
        from,
        to: to || 'PNG',
        fileId,
      },
    },
    data: {
      from,
      to: to || 'PNG',
      fileId,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const saveEditDocument = ({
  fileId,
  file,
  onSuccess,
  onFailed,
}: {
  fileId: string;
  file: File;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const formData = new FormData();
  formData.append('originalFileId', fileId);
  formData.append('file', file, transliterate(file.name));

  const action: IReduxAction = {
    type: EReduxTypes.SAVE_EDIT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/editor/save',
      body: formData,
      customHeaders: {
        'Content-Type': 'multipart/form-data',
      },
    },
    components: ['save_edit_document'],
    data: {
      fileId,
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const compressDocument = (
  { filename, key, size, pagesCount = 1 }: ICompressDocumentDto,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/optimize/file-uploaded',
      body: {
        filename,
        size,
        key,
        pagesCount,
      },
    },
    data: {
      filename,
      size,
      key,
      pagesCount,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: '.pdf',
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const splitExistingDocument = ({
  fileId,
  onSuccess,
  onFailed,
}: {
  fileId: string;
  onSuccess?: unknown;
  onFailed?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SPLIT_EXISTING_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/split/existing',
      body: {
        fileId,
      },
    },
    data: {
      fileId,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const splitDocument = ({
  filename,
  size,
  key,
  pagesCount,
  onSuccess,
  onFailed,
}: ISplitDocumentDto): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SPLIT_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/split/file-uploaded',
      body: {
        filename,
        size,
        key,
        pagesCount,
      },
    },
    data: {
      filename,
      size,
      key,
      pagesCount,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: '.pdf',
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const getSplittedFile = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_SPLITTED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/splitted`,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const getTranslatedDocument = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_TRANSLATED_DOCUMENT,
    payload: {
      method: 'GET',
      endpoint: `api/v1/translate/${fileId}/translated`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const getSupportedLanguages = (): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_SUPPORTED_LANGUAGES,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/translate/supported-languages',
    },
  };
  return action;
};

export const getMergedFile = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_MERGED_FILE,
    payload: {
      method: 'GET',
      endpoint: `api/v1/files/${fileId}/merged`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const setMergeDocumentData = (fileList: any[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_MERGE_DOCUMENT_DATA,
    data: fileList,
  };
  return action;
};

export const updateMergeDocumentImage = (
  index: number,
  thumbnail: string,
  onSuccess?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_MERGE_DOCUMENT_IMAGE,
    data: { index, thumbnail },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const pushMergeDocument = (
  file: IMergeDocument,
  options: {
    index?: number;
    ignoreEvent?: boolean;
    originalFormat?: string;
    onSuccess?: unknown;
    skipValidate?: boolean;
  } = {}
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.PUSH_MERGE_DOCUMENT_DATA,
    data: { file, index: options.index, skipValidate: options.skipValidate },
    onSuccess: (res: any) => {
      if (typeof options.onSuccess === 'function') options.onSuccess(res);

      if (!options.ignoreEvent) {
        const pagesCount = options.originalFormat ? Promise.resolve(1) : countPdfPages(file.file);

        pagesCount.then((pagesCount) => {
          Analytics.sendEvent({
            event: 'file_upload_status',
            data: {
              status: 'success',
              size: formatFileSize(file?.file?.size || 0),
              accurate_size: (file?.file?.size || 0) / 1000000,
              fileCounter: 1,
              file_format: options.originalFormat || '.pdf',
              pages_count: pagesCount,
            },
          });
        });
      }
    },
  };
  return action;
};

export const mergeDocuments = (
  data: IMergeDocumentDto[],
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.MERGE_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/merge/files-uploaded',
      body: {
        files: data,
      },
    },
    data: data,
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed();
      Analytics.sendEvent({ event: 'complete_merge_error' });
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          file_format: '.pdf',
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const convertingEvent = (userId: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERTING_EVENT,
    event: {
      path: `notifications/subscribe/converting/${userId}`,
    },
  };
  return action;
};

export const getParsedTextFromFile = (
  fileId: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_OCRED_FILE,
    payload: {
      method: 'POST',
      endpoint: `api/v1/files/${fileId}/ocred`,
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
    },
  };
  return action;
};

export const parseTextFromDocument = (
  { filename, key, size, exportFormat }: IOCRDocumentDto,
  fileFormat: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.PARSE_TEXT_FROM_DOCUMENT,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/ocr/file-uploaded',
      body: {
        filename,
        size,
        key,
        exportFormat,
      },
    },
    data: {
      filename,
      size,
      key,
      exportFormat,
    },
    onFailed: (res: any) => {
      if (typeof onFailed === 'function') onFailed(res);
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          file_format: fileFormat,
          is_validation_error: 'false',
        },
      });
    },
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const setSelectedFiles = (files: string[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_SELECTED_FILES,
    data: files,
  };
  return action;
};

export const convertMultipleDocuments = (
  {
    files,
    to,
    result_filename,
    post_process_action,
  }: {
    files: Array<{
      size: number;
      filename: string;
      key: string;
      pagesCount: number;
      orderIndex: number;
    }>;
    to: string;
    result_filename: string;
    post_process_action: string;
  },
  onSuccess?: (res: any) => void,
  onFailed?: (res: any) => void
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CONVERT_MULTIPLE_DOCUMENTS,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/converter/convert-multiple',
      body: {
        files,
        to,
        result_filename,
        post_process_action,
      },
    },
    onSuccess: (res: any) => {
      if (onSuccess) onSuccess(res);
    },
    onFailed: (res: any) => {
      if (onFailed) onFailed(res);
      void Analytics.sendEvent({
        event: 'file_upload_status',
        data: {
          status: 'fail',
          place: 'additional',
          errorCode: res?.response?.status,
          size: formatFileSize(files[0].size),
          accurate_size: files[0].size / 1000000,
          file_format: to,
          is_validation_error: 'false',
        },
      });
    },
  };
  return action;
};
