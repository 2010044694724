import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HttpStatusCode } from 'axios';
import classNames from 'classnames';

import { OngoingLoading } from 'components/ai-summarizer/ui/loader/ongoing-loading';

import apiEndpoints from 'use-cases/ai-summarizer/api/api';
import { redirectToChoosePlan } from 'use-cases/ai-summarizer/redirect-to-choose-plan';
import { validateLimit } from 'use-cases/ai-summarizer/validate-limit';
import { waitForThreadCompletion } from 'use-cases/ai-summarizer/wait-for-thread-completion';

import { Analytics } from 'services/analytics';

import {
  setCurrentThreadAssistantMessage,
  setCurrentThreadUserMessage,
  setFreeLimitReached,
} from 'data/actions/aiSummarizer';
import { triggerLoadingActionWithId } from 'data/actions/loading';
import { toggleModal } from 'data/actions/modals';
import { currentThreadDataSelector, currentThreadIdSelector } from 'data/selectors/aiSummarizer';
import { getLoadingSelector } from 'data/selectors/loading';
import { userDataSelector } from 'data/selectors/user';

import { EModalTypes } from 'ts/enums/modal.types';

export const Input = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentThreadId = useSelector(currentThreadIdSelector);
  const currentThreadData = useSelector(currentThreadDataSelector);
  const loadingCurrentThread = useSelector(getLoadingSelector('currentThread'));
  const loadingFileUpload = useSelector(getLoadingSelector('fileUpload'));
  const loadingNewMessageInThread = useSelector(getLoadingSelector('newMessageInThread'));

  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const user = useSelector(userDataSelector);
  const inputRef = useRef<HTMLInputElement>(null);

  const isLoading =
    loadingNewMessageInThread[currentThreadId as string] ||
    loadingFileUpload ||
    loadingCurrentThread;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setMessage('');
    }
  }, [currentThreadId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    Analytics.sendEvent({
      event: 'summarizer_send_promt',
    });

    const isReachedLimit = await validateLimit(user, currentThreadData);

    if (message.trim()) {
      setMessage('');
      if (currentThreadId) {
        try {
          if (!isReachedLimit) {
            dispatch(triggerLoadingActionWithId(['newMessageInThread'], true, currentThreadId));
            dispatch(setCurrentThreadUserMessage(message));
          }

          const response = await apiEndpoints.sendMessage(currentThreadId, message);

          const runId = response.runId;

          const { content, chatId } = await waitForThreadCompletion(currentThreadId, runId as any);

          const summary = content;

          if (summary) {
            dispatch(
              setCurrentThreadAssistantMessage({
                id: runId,
                threadId: chatId,
                role: 'assistant',
                message: summary,
              })
            );
            dispatch(triggerLoadingActionWithId(['newMessageInThread'], false, chatId));
          }
        } catch (error) {
          if (
            (error as any).response?.data.statusCode === HttpStatusCode.BadRequest &&
            (error as any).response?.data.message === 'error.openai.chat-not-ready'
          ) {
            return;
          }

          if ((error as any)?.response.data.statusCode === HttpStatusCode.PaymentRequired) {
            dispatch(setFreeLimitReached(true));

            if (!user?.email) {
              dispatch(toggleModal({ visible: true, type: EModalTypes.ENTER_EMAIL_AI_SUMMARIZER }));

              return;
            }

            redirectToChoosePlan(navigate);

            return;
          }
        }
      }
    }
  };

  return (
    <div className="pt-4 bg-[#fff]">
      <form
        onSubmit={handleSubmit}
        className={classNames(
          'flex rounded-[12px] bg-[#F6F6F6] px-4 py-2 transition-all duration-100 mx-6 relative border border-transparent',
          {
            'hover:border-[#192CD2]': !isLoading,
            'opacity-50 cursor-not-allowed group': isLoading,
          }
        )}
        data-testid="ai-summarizer-chat-form"
      >
        <input
          ref={inputRef}
          type="text"
          placeholder={t('ai_summarizer.input_placeholder')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={isLoading}
          onFocus={() => {
            Analytics.sendEvent({
              event: 'summarizer_start_promt',
            });
          }}
          className="flex-1 outline-none placeholder-[#575757] text-black bg-[#F6F6F6] leading-[130%] disabled:cursor-not-allowed"
          data-testid="ai-summarizer-chat-input"
        />

        {isLoading && <OngoingLoading className="translate-x-[20%] -translate-y-[20px]" />}

        <button
          type="submit"
          className={`h-10 w-10 rounded-full ${
            isLoading
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-[#3E4FE0] text-white border-transparent hover:bg-[#2C3CB3] hover:shadow-md'
          } flex items-center justify-center transition-colors`}
          aria-label="Send message"
          disabled={isLoading}
          data-testid="ai-summarizer-chat-submit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </button>
      </form>
    </div>
  );
};
