import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import new_chat from 'assets/ai-summarizer/new-chat.svg';
import { HttpStatusCode } from 'axios';
import { countPdfPages } from 'helpers/countPdfPages';
import useFileUpload from 'hooks/fileFlows/useFileUpload';

import { OngoingLoading } from 'components/ai-summarizer/ui/loader/ongoing-loading';

import apiEndpoints from 'use-cases/ai-summarizer/api/api';
import { buildThreadMessage } from 'use-cases/ai-summarizer/build-thread-message';
import { getDefaultChat } from 'use-cases/ai-summarizer/get-default-chat';
import { redirectToChoosePlan } from 'use-cases/ai-summarizer/redirect-to-choose-plan';
import { uploadFileToBucket } from 'use-cases/ai-summarizer/upload-file';

import { Analytics } from 'services/analytics';

import {
  removeCurrentThreadData,
  setChatsHistory,
  setCurrentFileUrl,
  setCurrentThreadId,
  setFileId,
  setFileName,
  setMobileMenuOpen,
  setNewFileData,
} from 'data/actions/aiSummarizer';
import { triggerLoadingAction } from 'data/actions/loading';
import { toggleModal } from 'data/actions/modals';
import {
  chatHistorySelector,
  currentFileUrlSelector,
  currentThreadIdSelector,
  newFileDataSelector,
} from 'data/selectors/aiSummarizer';
import { getLoadingSelector } from 'data/selectors/loading';
import { userDataSelector } from 'data/selectors/user';

import { getFileKeyFromAWSLink } from 'utils/getFileKeyFromAWSLink';
import { Logger } from 'utils/logger';

import { EModalTypes } from 'ts/enums/modal.types';

export const NewChatButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentThreadId = useSelector(currentThreadIdSelector);
  const currentFileUrl = useSelector(currentFileUrlSelector);
  const user = useSelector(userDataSelector);
  const chatsHistory = useSelector(chatHistorySelector);
  const newFileData = useSelector(newFileDataSelector);
  const loadingCurrentThread = useSelector(getLoadingSelector('currentThread'));
  const loadingNewMessageInThread = useSelector(getLoadingSelector('newMessageInThread'));
  const loadingFileUpload = useSelector(getLoadingSelector('fileUpload'));

  const { uploadFile } = useFileUpload();

  const navigate = useNavigate();
  const logger = new Logger('NewChatButton');

  const [ready, setReady] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const url = new URL(window.location.href);

  const isLoading =
    loadingFileUpload ||
    loadingCurrentThread ||
    (currentThreadId ? loadingNewMessageInThread[currentThreadId] : false);

  const handleClick = () => {
    if (!isLoading) {
      Analytics.sendEvent({
        event: 'upload_file_tap',
      });

      if (user && user?.email === null) {
        dispatch(toggleModal({ visible: true, type: EModalTypes.ENTER_EMAIL_AI_SUMMARIZER }));

        return;
      }

      if (user && !user?.subscription) {
        redirectToChoosePlan(navigate);

        return;
      }

      fileInputRef.current?.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    logger.log('user email', { email: user?.email } as any);
    logger.log('user subscription', { subscription: user?.subscription } as any);

    if (user?.email && !user?.subscription) {
      redirectToChoosePlan(navigate);

      return;
    }

    if (e.target.files?.[0]) {
      dispatch(setMobileMenuOpen(false));
      dispatch(removeCurrentThreadData());
      dispatch(triggerLoadingAction(['fileUpload'], true));
      dispatch(triggerLoadingAction(['chatsHistory'], true));

      if (e.target.files?.[0].size > 50 * 1024 * 1024) {
        dispatch(toggleModal({ visible: true, type: EModalTypes.AI_SUMMARIZER_MAX_SIZE_FILE }));

        Analytics.sendEvent({
          event: 'pdf_summarizer_max_size',
        });
        return;
      }

      if (url.searchParams.get('file')) {
        void navigate(url.pathname, { replace: true });
      }

      const file = e.target.files[0];

      const response = await uploadFileToBucket(uploadFile, file, dispatch);

      const { fileUrl, fileId } = response || {};

      logger.log('fileUrl ===>', { fileUrl });
      logger.log('fileId ===>', { fileId });

      if (file && fileUrl && fileId) {
        dispatch(setCurrentFileUrl(fileUrl));
        dispatch(triggerLoadingAction(['fileUpload'], false));
        dispatch(triggerLoadingAction(['currentThread'], true));

        const pagesCount = (await countPdfPages(file)) || 1;

        const data = {
          filename: file.name,
          size: file.size,
          key: getFileKeyFromAWSLink(fileUrl),
          pagesCount,
        };

        dispatch(setNewFileData(data));
        dispatch(setFileName(file.name));

        setUploadedFileId(fileId);
        setReady(true);
      }
    }
  };

  const setCurrentChat = async (chat: any, messages: any) => {
    dispatch(setCurrentThreadId(chat.chatId));
    dispatch(setFileId(chat.fileId));
    dispatch(setCurrentFileUrl(chat.file_url));
    dispatch(setFileName(chat.chatName));
    dispatch(setMobileMenuOpen(false));

    try {
      if (messages?.length) {
        buildThreadMessage(messages, chat.chatId, dispatch);
      }
    } catch (error) {}
  };

  const createChat = async () => {
    if (newFileData?.key) {
      try {
        const chat = await apiEndpoints.createChat(newFileData);
        const solidChatData = {
          id: chat.chatId,
          updated_at: new Date().toISOString(),
          ...chat,
        };
        dispatch(
          setChatsHistory(
            chatsHistory?.length > 0 ? [...chatsHistory, solidChatData] : [solidChatData]
          )
        );

        dispatch(setCurrentThreadId(chat.chatId));
        dispatch(setFileName(chat.chatName));

        if (chat.status === 'WAITING' && chat.chatId) {
          const interval = setInterval(async () => {
            try {
              const messages = await apiEndpoints.getAllMessages(chat.chatId);
              const sortedMessages = messages?.data?.sort(
                (a: any, b: any) => a.created_at - b.created_at
              );
              if (messages.data.length > 1 && sortedMessages[1]?.content[0]?.text?.value) {
                clearInterval(interval);
                await setCurrentChat(chat, messages.data);
                dispatch(triggerLoadingAction(['currentThread'], false));
              }
            } catch (err) {}
          }, 2000);
        }
      } catch (err) {
        if ((err as any).response?.status === HttpStatusCode.TooManyRequests) {
          Analytics.sendEvent({
            event: 'pdf_summarizer_daily_limit',
          });
          dispatch(toggleModal({ visible: true, type: EModalTypes.AI_SUMMARIZER_DAILY_LIMIT }));
          await getDefaultChat(chatsHistory, dispatch);
          return;
        }
        if ((err as any).response?.status === HttpStatusCode.BadRequest) {
          Analytics.sendEvent({
            event: 'pdf_summarizer_max_size',
          });
          dispatch(toggleModal({ visible: true, type: EModalTypes.AI_SUMMARIZER_MAX_SIZE_FILE }));
          return;
        }
        if ((err as any)?.response?.status === HttpStatusCode.PaymentRequired) {
          if (!user?.email) {
            dispatch(toggleModal({ visible: true, type: EModalTypes.ENTER_EMAIL_AI_SUMMARIZER }));
            return;
          }
          redirectToChoosePlan(navigate);
        }
      } finally {
        dispatch(triggerLoadingAction(['chatsHistory'], false));
      }
    }
  };

  useEffect(() => {
    if (ready && currentFileUrl && uploadedFileId) {
      createChat();
      setReady(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, currentFileUrl, uploadedFileId]);

  return (
    <div className="relative w-full">
      <button
        className={`small-desktop:mb-4 flex items-center justify-center gap-2 rounded-lg px-4 py-2.5 shadow-sm transition-colors duration-100 border max-w-[400px] w-full mx-auto relative ${
          isLoading
            ? 'bg-[#3E4FE0]/50 text-white cursor-not-allowed border-transparent group'
            : 'bg-[#3E4FE0] text-white border-transparent hover:bg-[#2C3CB3] hover:shadow-md'
        }`}
        onClick={handleClick}
        data-testid="ai-summarizer-new-chat-button"
      >
        <img src={new_chat} alt="new chat" className="mr-[3px] tablet:mr-[5px]" />

        <span className="font-[700]">{t('ai_summarizer.new_chat')}</span>

        <input
          type="file"
          accept=".pdf"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileChange}
          data-testid="ai-summarizer-file-input"
        />

        {isLoading && <OngoingLoading className="translate-x-[-60%] left-[100%]" />}
      </button>
    </div>
  );
};
