import { FC, ReactNode, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { setAbTests } from 'data/actions/abTests';
import { setQueryUserId } from 'data/actions/user';

import { IS_AUTO_TEST_LOCAL_STORAGE_KEY } from 'ts/constants/general';

interface IABTestProvider {
  children: ReactNode;
}

export const IS_MOBILE_KEY = 'isMobile';

export const ABTestProvider: FC<IABTestProvider> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const queryAbTest = searchParams.get('abTest');
  const queryUserId = searchParams.get('userId');
  const isAutoTestFromCookies = searchParams.get(IS_AUTO_TEST_LOCAL_STORAGE_KEY);

  const isAutoTest = !!localStorage.getItem(IS_AUTO_TEST_LOCAL_STORAGE_KEY);

  const abTestCookie = document?.cookie
    ?.split('; ')
    ?.find((row) => row.startsWith('abTest='))
    ?.split('=')[1];

  useEffect(() => {
    if (isAutoTestFromCookies === 'true') {
      localStorage.setItem(IS_AUTO_TEST_LOCAL_STORAGE_KEY, isAutoTestFromCookies);
    }
  }, [isAutoTestFromCookies]);

  useEffect(() => {
    if (searchParams.get(IS_MOBILE_KEY) === 'true') {
      localStorage.setItem(IS_MOBILE_KEY, 'true');
    }
  }, [searchParams]);

  useEffect(() => {
    if (queryUserId) {
      dispatch(setQueryUserId(queryUserId));
    }

    if (abTestCookie && !isAutoTest && isAutoTestFromCookies !== 'true') {
      try {
        const parsed = JSON.parse(abTestCookie);
        dispatch(setAbTests(parsed));
        // console.log('Using abTest from cookie:', { parsed });
      } catch (error) {
        console.log('Error parsing abTest cookie:', { error });
      }
    } else if (queryAbTest && !isAutoTest && isAutoTestFromCookies !== 'true') {
      try {
        const decoded = atob(queryAbTest);
        const parsed = JSON.parse(decoded);
        dispatch(setAbTests(parsed));
        // console.log('Using abTest from URL:', { parsed });
      } catch (error) {
        console.log('Error parsing abTest from URL:', { error });
      }
    }
  }, [abTestCookie, queryAbTest, queryUserId, dispatch, isAutoTest, isAutoTestFromCookies]);

  return children;
};
